/* .width-100 {
    width: 100% !important;
  }

.z-index-0 {
  z-index: 0 !important;
} */

.DateInput_input__small {
  line-height: 20px;
}