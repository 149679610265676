fieldset > legend {
    position: absolute;
    top: -12px;
    left: 25px;
    background-color: white;
    width: auto;
    font-size: small;
    padding: 0 5px 0 5px;
    color: gray;
}

.form-control.height-auto {
    height: auto;
}

.document-flow_form .documentType__is-invalid > div {
    border-color: red;
}
