/* .selected-deal > div:first-child, .selected-deal > span + div:first-child {
    min-height: unset;
    height: 31px;
}

.selected-deal > div:first-child > div + div {
    top: 43%;
} */

.page-item.active a.page-link {
    z-index: 0;
}

.person-info__list_filter_form .form-group {
    margin-bottom: 0;
}

.person-info__list_filter_form .form-group > label {
    font-size: x-small;
    margin-bottom: 0;
}

.person-info__list_filter_form label.form-check-label {
    font-size: medium;
}

.person-info__list_filter_form label.form-check-label > input {
    margin-right: 0.3em;
}