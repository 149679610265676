body {
  padding-top: 60px;
  padding-bottom: 20px;
  /*min-width: 540px;*/
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

.font-size-small {
  font-size: small;
}