.border-bottom { border-bottom: 1px solid #e5e5e5; }

.lh-125 { line-height: 1.25; }

.border-left-red {
    border-left: 3px solid #ff3300;
}

.text-red {
    color: #ff3300
}